/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.return-subsection {
  margin-left: 64px;
  margin-bottom: 32px;
}
.mobile .return-subsection {
  margin-left: 16px;
}
.return-subsection .divider {
  border-bottom: 1px dashed #d9d9d9;
  height: 1px;
  max-width: 800px;
  margin: 20px 0;
}
.return-row {
  margin-bottom: 12px;
}
.return-row .mobile {
  margin-bottom: 24px;
}
.return-row > label {
  color: #1890ff;
  width: 150px;
  text-align: right;
  margin-right: 8px;
  display: inline-block;
}
.mobile .return-row > label {
  display: block;
  text-align: left;
  margin-bottom: 2px;
  width: auto;
}
.return-row.full > label {
  text-align: left;
  display: block;
  margin-bottom: 4px;
}
.return-row .return-value {
  display: inline-block;
  color: #14837B;
}
.return-row .return-input {
  display: inline-block;
}
.mobile .return-row .return-input {
  display: block;
}
.return-row .return-time-range {
  display: block;
  margin-top: 8px;
  margin-bottom: 4px;
}
.return-row .help {
  color: #8c8c8c;
  margin-top: 2px;
  font-size: 0.95em;
  margin-left: 158px;
}
.mobile .return-row .help {
  margin-left: 0;
}
.service-return-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.right-button {
  margin: 0 0.2rem 0 auto;
}
.files-component {
  margin-right: 2rem;
}
.files-upload {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}
